<template>
  <v-row class="px-5">
    <v-col cols="12">
      <h2 class="text-center custom-title-large">Insira sua nova senha.</h2>
      <v-form ref="form" v-model="valid" @submit="submit">
        <inputPassword
          v-model="password"
          :password="{ label: 'Digite sua Nova Senha' }"
          :passwordConfirm="{ label: 'Confirme a Nova Senha' }"
        ></inputPassword>
        <div class="actions text-center">
          <v-btn
            class="custom-btn-green rounded-0 btn-large"
            type="submit"
            :disabled="!valid"
            :loading="loading"
          >
            Cadastrar
          </v-btn>
        </div>
      </v-form>
    </v-col>

    <k-dialog v-model="dialog" :title="dialogTitle">
      <template slot="content">
        <p class="custom-normal-text"
          >Ocorreu um erro com o link de reset de senha
        </p>
        <div v-show="error" class="text-center">
          <v-btn
            class="custom-btn-blue rounded-0 btn-large mt-3"
            :to="{ name: 'login-recover' }"
            >Reenviar link</v-btn
          >
        </div>
      </template>
    </k-dialog>
  </v-row>
</template>
<script>
import kDialog from '@src/components/dialog/Simple.vue'
import { translator } from '@src/translator'
import { authMethods } from '@state/helpers'
import inputPassword from '@src/components/changePassword/inputPassword.vue'

export default {
  name: 'passord-reset',
  props: ['token', 'emailB64'],
  data() {
    return {
      dialog: false,
      error: false,
      loading: false,
      passwordConfirm: '',
      password: '',
      valid: false,
    }
  },
  computed: {
    dialogTitle() {
      return 'Acesso expirado'
    },
  },
  methods: {
    ...authMethods,
    clear() {
      this.$refs.form.reset()
    },
    async submit(ev) {
      ev.preventDefault()
      try {
        if (this.$refs.form.validate()) {
          this.loading = true

          let data = {
            token: this.token,
            email: atob(this.emailB64),
            password: this.password,
            passwordConfirmation: this.password,
          }

          await this.passwordReset(data)

          let success = await this.logIn({
            username: data.email,
            password: data.password,
          })
          if (success) {
            this.tryingToLogIn = false
            this.$router.push(
              this.$route.query.redirectFrom || { name: 'home' }
            )
          }
        }
      } catch (err) {
        this.dialog = true
        this.error = true
        this.loading = false
      }
    },
  },
  components: {
    kDialog,
    inputPassword,
  },
}
</script>
<style lang="scss" scoped>
@import '@design';
</style>
