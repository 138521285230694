<template>
  <v-dialog max-width="400px" v-model="value">
    <v-card class="pt-1 d-flex flex-column justify-center align-center">
      <v-card-title class="custom-title-secondary">
        {{ title }}
      </v-card-title>
      <v-card-text class="text-center custom-normal-text">
        <div class="my-2">
          <slot name="content">{{ content }}</slot>
        </div>
        <v-btn
          class="custom-btn-blue rounded-0 btn-large mt-5"
          @click="close"
          v-if="closeButtom"
        >
          Fechar
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    value: {
      require: true,
      type: Boolean,
      default: () => false,
    },
    title: {
      require: true,
      type: String,
      default: () => 'Atenção',
    },
    content: {
      require: false,
      type: String,
      default: () => '',
    },
    closeButtom: {
      require: false,
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    onChange(ev) {
      this.$emit('input', this.value)
    },
    close() {
      this.$emit('input', false)
    },
  },
}
</script>
<style scoped lang="scss">
@import '@design';
</style>
